<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Aws from "@/services/Aws";

export default {
    components: { Layout, PageHeader },
    data() {
        return {
            title: "AWS Instances",
            error: null,
            tableData: [],
            currentPage: 1,
            perPage: 10,
            items: [
                {
                    text: "AWS",
                },
                {
                    text: "AWS Instances",
                    active: true,
                },
            ] ,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "InstanceId",
            sortDesc: true,
            isBusy: false,
            filterInstanceStatus: 0,
            fields: [
                {
                    key: "InstanceId",
                    label: "Instance Id",
                    sortable: false,
                },
                {
                    key: "InstanceName",
                    label: "Instance Name",
                    sortable: true,
                },
                {
                    key: "InstanceType",
                    label: "Instance Type",
                    sortable: false,
                },
                {
                    key: "PublicIpAddress",
                    label: "Public Ip",
                    sortable: false,
                },
                {
                    key: "State",
                    label: "State",
                    sortable: true,
                },
                {
                    key: "note",
                    label: "Note",
                    sortable: false,
                },
                "action",
            ],
            totalRows: 0,
            showModal: false
        };
    },
    async mounted() {
        await this.getInstances()
    },
    methods: {
        async getInstances() {
            try {
                this.isBusy = true;
                const response = await Aws.getInstances();
                this.originalTableData = response.data.data;
                this.tableData = [...this.originalTableData];
                this.totalRows = this.tableData.length;
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.tableData = [];
                this.totalRows = 0;
            } finally {
                this.showLoader = false;
                this.isBusy = false;
            }
        },
        getTagName(tags) {
            // Find the tag with the Key "Name"
            const nameTag = tags.find(tag => tag.Key === "Name");
            return nameTag ? nameTag.Value : "";  // Return the value or a fallback
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        setFilterInstances(){
            if (this.filterInstanceStatus) {
                // Filter for running instances
                this.tableData = this.originalTableData.filter(item => item.State === 'running');
            } else {
                // Reset to original data
                this.tableData = [...this.originalTableData]; // Spread operator to copy the original data
            }
            this.totalRows = this.tableData.length;
        }
    },
    middleware: "authentication",
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Total Instances: {{totalRows}}</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-3">
                                <div class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>


                            </div>
                            <div class="col-md-3">
                                <b-form-checkbox-group >
                                    <div class="form-check form-switch form-switch-lg mb-3" >
                                        <input v-model="filterInstanceStatus" @change="setFilterInstances"
                                               type="checkbox"
                                               class="form-check-input"
                                               id="filterInstanceStatus"
                                        />
                                        <label class="form-check-label" for="filterInstanceStatus">Only Running Instances</label>
                                    </div>
                                </b-form-checkbox-group>
                            </div>

                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isBusy"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(InstanceName)="data">
                                   <div>{{getTagName(data.item.Tags)}}</div>
                                </template>

                                <template v-slot:cell(State)="data">
                                    <div class="font-size-16" v-html="format_status(data.item.State)"></div>
                                </template>

                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <router-link title="View Instance" :to="{ path: `/aws/instances/${data.item.InstanceId}`}">
                                                <i class="uil uil-eye font-size-18"></i>
                                            </router-link>
                                        </li>
                                    </ul>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>
